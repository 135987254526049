// Detect "touch" and "no-touch" devices
const isMobile = {
  hasTouch: function() {
    return 'ontouchstart' in document.documentElement;
  },
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function() {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  },
  ismobi: function() {
    return navigator.userAgent.match(/Mobi/i);
  }
};
if (!isMobile.hasTouch()) {
  document.querySelector('body').classList.add('no-touch');
} else {
  document.querySelector('body').classList.add('touch');
}
try {
  const btnMenu = document.getElementById('btn-menu'),
      homeHeader = document.querySelector('.home-page .header'),
      sherryWeekHeader = document.querySelector('.sherryweek-page .header'),
      mainNavigation = document.getElementById('header-navigation'),
      menuItem = document.querySelectorAll('.touch .navigation-menu li.has-menu-secondary > a'),
      menuItemNoTouch = document.querySelectorAll('.no-touch .navigation-menu li.has-menu-secondary > a'),
      secondaryMenuItem = document.querySelectorAll('.touch .navigation-menu-dropdown .menu-secondary li.has-menu-third > a'),
      mobileHeader = document.querySelector('.header .mobile-header'),
      mobileHeaderBack = document.querySelector('.header .mobile-header .mobile-header-back'),
      mobileHeaderClose = document.querySelector('.header .mobile-header .mobile-header-close'),
      langButton = document.querySelector('.header-lang span'),
      langDropdown = document.querySelector('.header-lang .header-lang-menu');

// Menu button click and toggle navigation
  btnMenu.addEventListener('click', function () {
    btnMenu.classList.toggle('active');
    mainNavigation.classList.toggle('active');
    if (window.scrollY == 0) {
      homeHeader.classList.toggle('sticky');
      sherryWeekHeader.classList.toggle('sticky');
    }
  });
  mobileHeaderBack.addEventListener('click', function () {
    document.querySelectorAll('.navigation-menu-dropdown').forEach(el => {
      el.classList.remove('active');
    });
    mobileHeader.classList.remove('active');
  });
  mobileHeaderClose.addEventListener('click', function () {
    document.querySelectorAll('.navigation-menu-dropdown').forEach(el => {
      el.classList.remove('active');
    });
    mobileHeader.classList.remove('active');
    btnMenu.classList.remove('active');
    mainNavigation.classList.remove('active');
  });
  if (isMobile.hasTouch()) {
    langButton.addEventListener('click', function () {
      langDropdown.classList.toggle('active');
    });
  }

// Menu dropdowns
  if (isMobile.hasTouch()) {
    if (window.innerWidth > 1023) {
      document.querySelectorAll('.navigation-menu li.has-menu-secondary > a').forEach(el => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          // console.log('Clicked!');
          const menuDropdownMain = document.querySelectorAll('.navigation-menu li.has-menu-secondary .navigation-menu-dropdown');
          if (!el.parentElement.classList.contains('active')) {
            for (let i = 0; i < menuDropdownMain.length; i++) {
                menuDropdownMain[i].classList.remove('active');
            }
            el.parentElement.querySelector('.navigation-menu-dropdown').classList.add('active');
          } else {
            for (let j = 0; j < menuDropdownMain.length; j++) {
                menuDropdownMain[j].classList.remove('active');
            }
          }
        });
      });
    }
  }
  if (menuItemNoTouch.length > 0) {
    document.querySelector('.navigation-menu li .navigation-menu-dropdown .menu-secondary li:first-child .menu-third').classList.add('desktop-active');
    const current = document.querySelectorAll('.navigation-menu li .navigation-menu-dropdown .menu-secondary li:not(:first-child)');
    current.forEach(el => {
      el.addEventListener('mouseenter', () => {
        document.querySelector('.navigation-menu li .navigation-menu-dropdown .menu-secondary li:first-child .menu-third').classList.remove('desktop-active');
      });
    });
  }
  if (menuItem.length > 0) {
    document.querySelector('.navigation-menu li:first-child .navigation-menu-dropdown').classList.add('tablet-active');
    menuItem.forEach(el => {
      let menuSecondToggle = document.createElement('span');
      menuSecondToggle.setAttribute('class', 'menu-second-toggle');
      el.prepend(menuSecondToggle);
    });
    const menuSecondToggle = document.querySelectorAll('.header-navigation .menu-second-toggle');
    menuSecondToggle.forEach(el => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        // console.log('Clicked!');
        document.querySelector('.navigation-menu li .navigation-menu-dropdown').classList.remove('tablet-active');
        document.querySelectorAll('.navigation-menu li.has-menu-secondary .navigation-menu-dropdown').forEach(elOther => {
          elOther.classList.remove('active');
        });
        el.parentElement.nextElementSibling.classList.toggle('active');
        mobileHeader.classList.add('active');
      });
    });
  }
  if (secondaryMenuItem.length > 0) {
    secondaryMenuItem.forEach(el => {
      let menuThirdToggle = document.createElement('span');
      menuThirdToggle.setAttribute('class', 'menu-third-toggle');
      el.prepend(menuThirdToggle);
    });
    const menuThirdToggle = document.querySelectorAll('.navigation-menu-dropdown .menu-third-toggle');
    menuThirdToggle.forEach(el => {
      let menuThird = el.parentElement.parentElement.querySelector('.menu-third');
      el.addEventListener('click', (e) => {
        e.preventDefault();
        // console.log('Clicked!');
        el.classList.toggle('active');
        menuThird.classList.toggle('active');
      });
    });
  }

// Sticky header
  window.addEventListener('scroll', function () {
    if (document.body.classList.contains('home-page')) {
      if (window.scrollY > 0) {
        homeHeader.classList.add('sticky');
      } else {
        homeHeader.classList.remove('sticky');
      }
      if (window.scrollY == 0) {
        homeHeader.classList.remove('sticky');
        mainNavigation.classList.remove('active');
        btnMenu.classList.remove('active');
      }
    }
  });

  window.addEventListener('scroll', function () {
    if (document.body.classList.contains('sherryweek-page')) {
      if (window.scrollY > 0) {
        sherryWeekHeader.classList.add('sticky');
      } else {
        sherryWeekHeader.classList.remove('sticky');
      }
      if (window.scrollY == 0) {
        sherryWeekHeader.classList.remove('sticky');
        mainNavigation.classList.remove('active');
        btnMenu.classList.remove('active');
      }
    }
  });

// Orange Menu
  const navbarCustomOpen = document.querySelector('.navbar-custom .navbar-custom-open'),
      navbarCustomClose = document.querySelector('.navbar-custom .navbar-custom-close'),
      navbarCustomToggleDropdown = document.querySelectorAll('.navbar-custom .navbar-custom-wrap .navbar-toggle-dropdown'),
      togglebar = document.getElementById('navbar-custom');

  navbarCustomOpen.addEventListener('click', function () {

    document.querySelector('.navbar-custom .navbar-custom-menu').classList.add('active');
    togglebar.style.zIndex = 99999;
  });
  navbarCustomClose.addEventListener('click', function () {
    document.querySelector('.navbar-custom .navbar-custom-menu').classList.remove('active');
    togglebar.style.zIndex = 100;
  });
  navbarCustomToggleDropdown.forEach(el => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      // console.log('Clicked!');
      el.classList.toggle('active');
      el.parentElement.parentElement.querySelector('ul').classList.toggle('active');
    });
  });
}
catch (e) {
  //console.log('o');
}

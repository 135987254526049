// This is the scss entry file
import "../styles/index.scss";
import Macy from "macy";
import Alpine from 'alpinejs';
import Axios from 'axios';
import Masonry from 'masonry-layout';


import MarkersOnMap from 'markers-on-map';

// new Masonry( '.grid', {
//   // options
//     itemSelector: '.grid-item',
// });


// import Cookies from 'js-cookie';
//
// const cookieMonsterContainer = document.getElementById("cookie-monster-container");
// if(!Cookies.get('cookie-monster')){
//     cookieMonsterContainer.classList.add("showcookies");
// }
// const cookieMonsterButton = document.getElementById("cookie-monster");
// cookieMonsterButton.addEventListener('click', function () {
//     cookieMonsterContainer.classList.add("fade-out");
//     Cookies.set('cookie-monster',true,{ expires: 365 });
//
//     setTimeout(function(){
//         cookieMonsterContainer.style.display = 'none';
//     }, 3000);
//
// });

const iframes = document.querySelectorAll('iframe');
if(iframes) {
    iframes.forEach((iframe) => {
        const src = iframe.getAttribute("src");
        iframe.setAttribute("src", src + '&rel=0');
    });
}


const links = document.querySelectorAll('a');
links.forEach((obj)=>{
    if (typeof obj.getAttribute("href") === 'string' || obj.getAttribute("href") instanceof String) {
        if (obj.getAttribute("href").substring(0, 7) == 'http://' || obj.getAttribute("href").substring(0, 8) == 'https://') {
            obj.setAttribute('target', '_blank');
        }
    }
});


window.Alpine = Alpine;
window.axios = Axios;
window.Macy = Macy;
window.Masonry = Masonry;
window.MarkersOnMap = MarkersOnMap;


const clickers = document.querySelectorAll('.clicker');
if(clickers){
    clickers.forEach(obj => {
        const a =  obj.querySelector('a');
        const href =  a.getAttribute("href");
        obj.addEventListener('click',()=>{
            location.href = href;
        });
        obj.style.cursor = 'pointer';
    });
}



if(document.getElementsByClassName('macy-grid').length > 0){
    Macy({
    container: '.macy-grid',
    trueOrder: false,
    waitForImages: false,
    margin: 15,
    columns: 4,
    breakAt: {
        1200: 4,
        940: 3,
        520: 2,
        400: 1
    }
});
}
const gridx = document.querySelectorAll('.grid-x');

if(gridx){
    gridx.forEach(obj => {
        const id = obj.getAttribute("id");
        Macy({
            container: '#' + id,
            trueOrder: false,
            waitForImages: false,
            margin: 15,
            columns: 4,
            breakAt: {
                1200: 4,
                940: 3,
                520: 2,
                400: 1
            }
        });
    });
}





//Alpine.start();
import "../components/custom-datepicker";
import "../components/custom-tabs";
import "../components/mobile-filter";
import "../components/navigation";
import "../components/modal-video";
import "../components/parallax";
import "../components/gallery-slider";
import "../components/grid-bottles-slider";

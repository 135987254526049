import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/ranges';

const customDatepicker = document.getElementById('custom-datepicker');

if (customDatepicker) {
    new Litepicker({
        element: customDatepicker,
        //inlineMode: false,
        plugins: ['ranges'],
        setup: (picker) => {
            picker.on('selected', (date1, date2) => {


                const dateChanger = new CustomEvent('date-changer', {
                    detail: {
                        date1: date1,
                        date2: date2
                    }
                });
                window.dispatchEvent(dateChanger);
            });
        }
    });
}


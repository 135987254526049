const openVideo = document.querySelectorAll('.block-video_links .open-video-modal'),
modalVideo = document.querySelectorAll('.modal-video'),
modalVideoOverlay = document.querySelectorAll('.modal-video .modal-video-overlay'),
closeVideo = document.querySelectorAll('.modal-video .close-modal');
openVideo.forEach(el => {
    el.addEventListener('click', (e) => {
        e.preventDefault();
        // console.log('Clicked!');
        const elTarget = el.getAttribute('href');
        document.querySelector(elTarget).classList.add('active');
        for (let j = 0; j < modalVideoOverlay.length; j++) {
            modalVideoOverlay[j].classList.add('active');
        }
    });
});
closeVideo.forEach(el => {
    el.addEventListener('click', () => {
        for (let i = 0; i < modalVideo.length; i++) {
            modalVideo[i].classList.remove('active');
        }
    });
});
modalVideoOverlay.forEach(el => {
    el.addEventListener('click', () => {
        for (let k = 0; k < modalVideo.length; k++) {
            modalVideo[k].classList.remove('active');
        }
        el.classList.remove('active');
    });
});

import {tns} from 'tiny-slider';

if (document.body.contains(document.querySelector('.gallery-slider-section'))) {
  const prevArrow = document.querySelector('.gallery-slider-controls .gallery-slider-prev'),
  nextArrow = document.querySelector('.gallery-slider-controls .gallery-slider-next');

  const gallerySlider = tns({
    loop: false,
    container: '.gallery-slider',
    controlsContainer: '.gallery-slider-wrap .gallery-slider-controls',
    navContainer: '.gallery-slider-pager',
    items: 1,
    navAsThumbnails: true,
    controls: true,
    mouseDrag: false
  });

  const gallerySliderPager = tns({
    loop: false,
    container: '.gallery-slider-pager',
    items: 5,
    mouseDrag: true,
    nav: false,
    controls: false,
    gutter: 10,
    responsive: {
      1216: {
        items: 5,
        gutter: 25,
      }
    }
  });

  prevArrow.addEventListener('click', () => {
    gallerySliderPager.goTo('prev');
  });
  nextArrow.addEventListener('click', () => {
    gallerySliderPager.goTo('next');
  });

  console.log(gallerySlider, gallerySliderPager);
}

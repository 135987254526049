if(document.querySelector('.filter-data-section-wrap .open-filter')) {
    const openMobileFilter = document.querySelector('.filter-data-section-wrap .open-filter'),
        closeMobileFilter = document.querySelector('.filter-data-section-wrap .filter-data-section-header .close-filter'),
        mobileFilter = document.querySelector('.filter-data-section-wrap .filter-data-section'),
        openMobileFilterAccordion = document.querySelectorAll('.filter-data-section-wrap .filter-data-section .filter-data-box .expand-filter-box');
    openMobileFilter.addEventListener('click', () => {
        // console.log('Clicked!');
        mobileFilter.classList.toggle('active');
    });
    closeMobileFilter.addEventListener('click', (e) => {
        e.preventDefault();
        mobileFilter.classList.remove('active');
    });
    openMobileFilterAccordion.forEach(el => {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            // console.log('Clicked!');
            el.parentElement.querySelector('.filter-data-box-inner').classList.toggle('active');
            el.classList.toggle('active');
        });
    });
}

import {tns} from 'tiny-slider';

const gridBottlesSlider = document.querySelectorAll('.grid-bottles-section .grid-bottles-slider');
gridBottlesSlider.forEach(slider => {
  // console.log(slider, slider.parentElement);
  const currentSliderControls = slider.parentElement.parentElement.querySelector('.grid-bottles-slider-controls');
  const tnsSlider = tns({
    container: slider,
    controlsContainer: currentSliderControls,
    items: 1,
    autoWidth: false,
    mouseDrag: true,
    autoplay: false,
    controls: true,
    loop: false,
    nav: true,
    gutter: 20,
    responsive: {
        1216: {
            items: 3,
            gutter: 30,
        },
        1024: {
            items: 3,
            gutter: 20,
        },
        769: {
            items: 2,
            gutter: 20,
        }
    }
  });
  console.log(tnsSlider);
});
